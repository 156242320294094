import React from 'react';
import './style.css';

import { 
  Container,
  Row,
  Col, 
} from 'reactstrap';


export default function WeHelp() {



  return (
    <React.Fragment>
      <section id="works" className="py-5 py-md-5">
        {/* Services */}
        <Container className="py-5 py-md-5 " >
            <Row className="py-5 py-md-5 align-items-center justify-content-between viewport-section in-viewport" >
                <Col className="col-12 col-md-5 fadeInUp" >
                    <h2 className="display-1 font-weight-bold">Who we help</h2>
                    <p className="font-size-lg text-muted mb-6">We have worked across many industries including but not limited to Government, Non-profit, Utilities, Education, Retail, B2B & B2C.</p> 
                    <p className="font-size-lg text-muted mb-6">By intimately understanding your industry and specific needs we can deliver a solution that exceeds your expectations.</p> 
                </Col>
                <Col className="col-md-6 fadeInUp" >
                    <>
                        <a href="https://twitter.com/soyjosecaicedo/status/1257005689475645442" target="_blank" rel="noopener noreferrer" className="esEvwG rounded shadow-lg bg-dark text-muted p-5 text-decoration-none">
                            <blockquote className="ghirmh">“Una Página Web no es solo mostrar información. Debemos entender que los usuarios son los que interactúan con esa información.”</blockquote>
                            <div className="d-flex align-items-center">
                                <img src="https://pbs.twimg.com/profile_images/1239865389120659456/quCuYr2o.jpg" alt="Jose Caicedo" className="rounded-circle border border-dark mr-3 gSpnyT"/>
                                    <div>
                                        <p className="hMGdqX">Jose Caicedo</p>
                                        <p className="hMGdqX">CEO, SJ Social Media</p>
                                    </div>
                            </div>
                        </a>

                
                    </>
                </Col>
            </Row>
        </Container>
      </section>
    </React.Fragment>
  );
}