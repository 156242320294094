import React from 'react';
import './style.css';
import weare from '../images/Home_We-are-3.svg';

import { Container, Row, Col } from 'reactstrap';


export default function About() {

  return (
    <React.Fragment>
      <section id="about" className="py-5 bg-dark text-light">
        {/* Services */}
        <Container className="">
          <Row className="align-items-center">
            <Col className="col-12 col-md-4 order-2 order-md-1" >
              <img src={weare} alt={weare}/>
            </Col>
            <Col className="col-12 col-md-8 order-1 order-md-2" >
                <div className="" >
                    <h2 className="display-1 font-weight-bold">We Are</h2>
                    <p className="font-size-lg text-muted mb-6">SJ Social Media Group, a Growth Marketing consulting agency that is absolutely passionate about Growth. We live and breathe online marketing like nobody else. We have been obtaining excellent results for customers since 2011, working with leading brands such as Danone, BMW, Glovo, Harinera del Valle and Shopify.</p> 
                </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
}

