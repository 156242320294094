import React from 'react';
import { useStyles } from '../common/Palette'

import {
  Container,
  CssBaseline,
  Grid,
  Button,
  Typography,
  Avatar,
  TextField,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

const sgMail = require('@sendgrid/mail')
sgMail.setApiKey('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');

const msg = {
    to: 'info@sjsocialmedia.com', // Change to your recipient
    from: 'react@sjsocialmedia.com', // Change to your verified sender
    subject: 'Sending with SendGrid is Fun',
    text: 'and easy to do anywhere, even with Node.js',
    html: '<strong>and easy to do anywhere, even with Node.js</strong>',
}



export default function Form() {

    const classes = useStyles();

    const [open, setOpen] = React.useState(false);

    const sendEmail = () => {
        sgMail.send(msg);
    };    
  
    return (
        <Container component="main" maxWidth="xs">
            <DialogTitle id="form-dialog-title">
                <Grid container justify="center" alignItems="center" spacing={2}>
                    <Grid item justify="flex-end" xs={2} sm={2}>
                        <Avatar className={classes.avatar}>
                            <LockOutlinedIcon />
                        </Avatar>
                    </Grid>
                    <Grid item xs={10} sm={10}>
                        <Typography component="h1" variant="h5">
                            Formulario de Contacto
                        </Typography>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <form className={classes.form} noValidate>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField autoComplete="name" name="name" id="name" variant="outlined" required fullWidth label="Nombre Completo" autoFocus/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField autoComplete="email" name="email" id="email" variant="outlined" required fullWidth label="Correo Electronico" />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField type="text" name="message" id="message" variant="outlined" required fullWidth label="Mensaje" multiline rows={5}/>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel control={<Checkbox value="allowExtraEmails" color="primary" />} label="Quiero recibir inspiración, promociones de marketing y actualizaciones por correo electrónico."/>
                        </Grid>
                    </Grid>
                    <DialogActions>
                        <Button type="submit" variant="contained" color="secondary" className={classes.submit} onClick={sendEmail}>
                            Enviar
                        </Button>
                    </DialogActions>
                </form>
            </DialogContent>
        </Container>
    );
}