import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import {useStyles} from '../common/Palette'

export default function Album() {
    const classes = useStyles();
  
    return (
      <React.Fragment>
        <CssBaseline />
        <main>
          {/* Hero unit */}
          <div className={classes.heroContent}>
            <Container maxWidth="sm">
              <Typography variant="h3" align="center" color="textPrimary" gutterBottom>
                Garantía de devolución del dinero por 30 días
              </Typography>
              <Typography variant="h5" align="center" color="textSecondary" paragraph>
                Si no quedas satisfecho con nuestro servicio por cualquier motivo, haremos un reembolso de tu pago. sin problemas, sin riesgos.
              </Typography>
              <div className={classes.heroButtons}>
                <Grid container spacing={2} justify="center">
                  <Grid item>
                    <Button variant="contained" color="secondary">
                      Empieza YA
                    </Button>
                  </Grid>
                </Grid>
              </div>
              <div className={classes.heroContent}></div>
            </Container>
          </div>
        </main>
      </React.Fragment>
    );
  }