import React from 'react';
import './style.css';
import HeaderBack from '../images/169bigdata.jpg';

// Bootstrap
import { 
  Row,
  Container,
  Col,
} from 'reactstrap';

// get our fontawesome imports
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
  faLaptop,
  faMobileAlt,
  faDesktop,
  faArrowDown,
} from "@fortawesome/free-solid-svg-icons";



export default function Header() {

  return (
    <React.Fragment>
      <header id="header" className="position-relative overflow-hidden text-left py-5 p-sm-3 ">
        <img src={HeaderBack} alt={HeaderBack}/>
        <Col id="bordersj" className="col-10 col-sm-8 p-sm-3 mx-auto py-2 mb-5 text-secondary">
          <p className="display-1 font-weight-bold pl-sm-3">Growth Marketing <span className="color-title">and Customer Acquisition Solutions</span></p>
          <p className="font-weight-bolder  h3">We help companies increase their sales exponentially. With &nbsp;<span className = "color-title">more than 200 satisfied customers </span> lead the market.</p>
        </Col>
        <Container className="d-flex justify-content-sm-center p-sm-3">
          <Row>
              <Col className="col-6 col-sm-3">
                <p className="text-center"><span className="big-icon icon icon-shape icon-shape-primary rounded-circle escale"><FontAwesomeIcon icon={faLaptop} size="3x" className = "class-icon"/></span></p>
              </Col>
              <Col className="col-6 col-sm-3">
                <p className="text-center"><span className="big-icon icon icon-shape icon-shape-primary rounded-circle escale"><FontAwesomeIcon icon={faMobileAlt} size="3x" /></span></p>
              </Col>
              <Col className="col-6 col-sm-3">
                <p className="text-center"><span className="big-icon icon icon-shape icon-shape-primary rounded-circle escale"><FontAwesomeIcon icon={faMobileAlt} size="3x" transform={{ rotate: -90 }} /></span></p>
              </Col>
              <Col className="col-6 col-sm-3">
                <p className="text-center"><span className="big-icon icon icon-shape icon-shape-primary rounded-circle escale"><FontAwesomeIcon icon={faDesktop} size="3x" /></span></p>
              </Col>
          </Row>
        </Container>
        <div>
          <Container className="d-flex justify-content-center">
            <Row>
              <FontAwesomeIcon icon={faArrowDown} size="xs" className = "class-icon color-title"/>
            </Row>
          </Container>
        </div>
      </header>
    </React.Fragment>
  );
}