import React from 'react';
import './style.css';
import Bancolombia from '../images/white_Bancolombia.png';
import Colombina from '../images/white_Colombina.png';
import GoDaddy from '../images/white_GoDaddy.png';
import HarineraDelValle from '../images/white_HarineraDelValle.png';
import Johnsons from '../images/white_Johnsons.png';



import { 
  Container,
  Row,
  Col,
} from 'reactstrap';


export default function brands() {

  return (
    <React.Fragment>
      <section id="brands" className="py-5 py-md-5 text-light background_gradient padding_sections">
        {/* Brands */}
          <Container className=" py-5 py-md-5 my-3 my-md-5 ">
            <h2 className="display-1 font-weight-bold">Some Brands</h2>
            <p className="font-size-lg text-dark mb-6">have trusted us.</p> 
            <Row className=" align-items-center justify-content-center">
              <div className="col-6 col-sm-4 col-md-2 mb-4 mb-md-0">        
                {/*  Brand  */}
                <div className="img-fluid text-dark mb-2 mb-md-0 svg-shim">
                  <img className="w-100 animation-grow filter_white" alt="Logo Bancolombia" src={Bancolombia}/>
                </div>
              </div>
              <Col className="col-6 col-sm-4 col-md-2 mb-4 mb-md-0">        
                {/*  Brand  */}
                <div className="img-fluid text-dark mb-2 mb-md-0 svg-shim">
                  <img className="w-100 animation-grow filter_white" alt="Logo Colombina" src={Colombina}/>
                </div>
              </Col>
              <Col className="col-6 col-sm-4 col-md-2 mb-4 mb-md-0">        
                {/*  Brand  */}
                <div className="img-fluid text-dark mb-2 mb-md-0 svg-shim">
                  <img className="w-100 animation-grow filter_white" alt="Logo GoDaddy" src={GoDaddy}/>
                </div>
              </Col>
              <Col className="col-6 col-sm-4 col-md-2 mb-4 mb-md-0">        
                {/*  Brand  */}
                <div className="img-fluid text-dark mb-2 mb-md-0 svg-shim">
                  <img className="w-100 animation-grow filter_white" alt="Logo Harinera Del Valle" src={HarineraDelValle}/>
                </div>
              </Col>
              <Col className="col-6 col-sm-4 col-md-2 mb-4 mb-md-0">        
                {/*  Brand  */}
                <div className="img-fluid text-dark mb-2 mb-md-0 svg-shim">
                  <img className="w-100 animation-grow filter_white" alt="Logo Johnsons" src={Johnsons}/>
                </div>
              </Col>
            </Row> 
          </Container>        
      </section>
    </React.Fragment>
  );
}


