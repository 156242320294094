import React from 'react';
import './style.css';
import Header from  './header';
import Price from './Pricing'
import Features from './Features'
import Warranty from './Warranty'
export default function Hosting() {

  return (
    <React.Fragment>
    {/* Header */}
    <Header />
    {/* Features */}
    <Features />
    {/* Price Table */}
    <Price />
    {/* Warranty */}
    <Warranty />


        
    </React.Fragment>
  );
}