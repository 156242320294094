import React from 'react';
import './style.css';
import Header from  './header';
import Services from  './services';
import Works from  './works';
import About from  './about';
import Contact from  './contact';
import Brands from  './brands';
import Wehelp from  './wehelp';
export default function Home() {

  return (
    <React.Fragment>
        {/* Header */}
        <Header />
        {/* What We Do */}
        <Services />
        {/* Our Works */}
        <Works />
        {/* We Are */}
        <About />
        {/* Who we help */}
        <Wehelp />
        {/* Some Brands */}
        <Brands />
        {/* Let's talk */}
        <Contact />
    </React.Fragment>
  );
}