import React from 'react';
import './style.css';



import { 
  Container,
  Col,
  Row,
} from 'reactstrap';


export default function Contact() {

  return (
    <React.Fragment>
      <section id="contact" className="py-5">
        {/* Services */}
        <Container  className="py-5">
         <h2 className="display-1 font-weight-bold pl-sm-3">Let's talk.</h2>
          <Row className="justify-content-center justify-content-md-center">
          <form className="js-validate mx-5" >
            <Row>
              <Col className="col-12 mb-4">
                <div className="js-form-message">
                  <input type="text" className="form-control" name="name" placeholder="Your name" data-msg="Please enter your name."/>
                </div>
              </Col>
              <Col className="col-12 mb-4">
                <div className="js-form-message">
                  <input type="phone" className="form-control" name="phone" placeholder="Your phone number" required="" data-msg="Please enter a valid phone number."/>
                </div>
              </Col>
              <Col className="col-12 mb-4">
                <div className="js-form-message">
                  <input type="email" className="form-control" name="email" placeholder="Your email " required="" data-msg="Please enter a valid email address."/>
                </div>
              </Col>
            </Row>
            <div className="js-form-message mb-6">
              <div className="input-group">
                <textarea className="form-control" rows="4" name="text" placeholder="How can we help you?" required="" data-msg="Please enter a reason."></textarea>
              </div>
            </div>
            <div className="text-center mx-5 my-4">
              <button id="button" type="submit" className="btn btn-dark rounded btn-wide transition-3d-hover">Submit</button>
              <p className="small">We'll get back to you in 1-2 business days.</p>
            </div>
          </form>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
}



