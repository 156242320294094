import React from 'react';
import './style.css';
import CourseImg1 from '../images/course-img-1.png';
import CourseImg2 from '../images/course-img-2.png';
import CourseImg3 from '../images/course-img-3.png';


import { 
  Container,
  Row,
  Col, 
  NavLink,
} from 'reactstrap';


export default function Works() {

  return (
    <React.Fragment>
      <section id="works" className="py-5">
        {/* Services */}
        <Container>
          <Row className="d-flex align-items-center">
            <Col className="col-12 col-lg-6 col-md-12">
              <div className="pr-10 mb-8 mb-lg-0">
              <h2 className="display-1 font-weight-bold pl-sm-3">Ours Works</h2>
                <p className="">
                A Glimpse Into Our Award-Winning Website Portfolio.<br/>
                Having Spent Years In The Industry, We Have A Wide Portfolio To Talk About
                </p>
              </div>
            </Col>
            <Col className="col-12 col-lg-6 col-md-6 mb-5">
              <div className="img-overlay">
                <NavLink href="">
                  <img className="rounded img-fluid scrolling-container" src={CourseImg1} alt=""/>
                </NavLink>
                {/*<div className="caption">
                  <NavLink href="pages/podcast.html" id="button" className="btn btn-light rounded btn-lg">Ver Aqui</NavLink>
                </div>*/}
              </div>
            </Col>
            <Col className="col-12 col-lg-6 col-md-6 mb-5">
              <div className="img-overlay">
                <NavLink href="">
                  <img className="rounded img-fluid" src={CourseImg2} alt=""/>
                </NavLink>
                {/*<div className="caption">
                  <NavLink href="pages/events.html" id="button" className="btn btn-light rounded btn-lg">Ver Aqui</NavLink>
                </div>*/}
              </div>
            </Col>
            <Col className="col-lg-6 col-md-4 mb-5 d-md-none d-lg-block">
              <div className="img-overlay">
                <NavLink href="">
                  <img className="rounded img-fluid" src={CourseImg3} alt=""/>
                </NavLink>
                {/*<div className="caption">
                  <NavLink href="pages/program-list.html" id="button" className="btn btn-light rounded btn-lg">Ver Aqui</NavLink>}
                </div>*/}
              </div>
            </Col>
          </Row>    
        </Container>
      </section>
    </React.Fragment>
  );
}