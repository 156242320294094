import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import {useStyles} from '../common/Palette'

// get our fontawesome imports
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
  faShieldAlt,
  faRocket,
  faTools,
  faCloudUploadAlt,
  faTachometerAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  faWordpress,
} from "@fortawesome/free-brands-svg-icons"



const cards = [
  {
    title: "Optimizado WordPress",
    excerpt: "Crea fácil y rápidamente tu página web, sin necesidad de código, aprovecha al máximo tu imaginación.",
    image: faWordpress,
   },
  
   {
    title: "Seguridad Constante",
    excerpt: "Tenemos procesos de seguridad diseñados especialmente para el hosting WordPress chequeamos constantemente tu sitio web.",
    image: faShieldAlt,
   },
  
   {
    title: "Alto Rendimiento",
    excerpt: "Te proveemos de un hosting optimizado para que disfrutes de una mejora considerable en la velocidad de carga de tu web.",
    image: faRocket,
   },
  
   {
    title: "Herramientas Increíbles",
    excerpt: "Nuestra plataforma cuenta con herramientas integradas para acelerar la creación de sitios en WordPress.",
    image: faTools,
   },
  
   {
    title: "Backup Automatizado",
    excerpt: "Duerme tranquilo ya que siempre tendras una copia de tu sitio web, para que te sigas concentrando en crear contenido increíble.",
    image: faCloudUploadAlt,
   },
  
   {
    title: "Rendimiento Constante",
    excerpt: "Tu negocio necesita un sitio web ágil. Por eso monitoreamos el rendimiento de éste con regularidad para así reaccionar si algo sale mal.",
    image: faTachometerAlt,
   }
];

export default function Album() {

  const classes = useStyles();


  return (
    <React.Fragment>
      <CssBaseline />
      <div className={classes.heroContent}>
        <Container className={classes.heroContent} maxWidth="md">
          <Typography component="h2" variant="h3" align="center" color="textPrimary" gutterBottom>
            Descubre la Mejor Experiencia en Hosting
          </Typography>
          <Typography variant="h5" align="center" color="textSecondary" paragraph>
          Con nuestros planes de Hosting de páginas web Wordpress podrás crear un site profesional en pocos minutos o alojar uno que ya tengas armado, sin importar dónde. Nos adaptamos a tu nivel de conocimiento y siempre contarás con alguien para ayudarte del otro lado.
          </Typography>
          <Grid container spacing={4}>
            {cards.map(cards => (
              <Grid item key={cards.title} xs={12} sm={6} md={4}>
                <Card className={classes.cards}>
                  <Typography gutterBottom variant="h5" component="h2" align="center" >
                    <FontAwesomeIcon icon={cards.image} size="3x" className={classes.icon}/>
                  </Typography>
                  <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h5" component="h2" align="center" >
                      {cards.title}
                    </Typography>
                    <Typography align="center">
                      {cards.excerpt}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </div>
    </React.Fragment>
  );
}