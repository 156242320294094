import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import {useStyles} from '../common/Palette'

export default function Header() {

  const classes = useStyles();



  return (
    <React.Fragment>
        <CssBaseline />
        <div className={classes.HeaderBack}>
          <div className={classes.backgroundOverlay}></div>
          <Container maxWidth="md" component="main" className={classes.heroContent}>
            <Typography component="h1" variant="h2" align="center" className={classes.textWhite} gutterBottom>
            Contactanos
            </Typography>
            <Typography variant="h5" align="center" className={classes.textLight} component="p">
              A traves de este formulario nos puedes contactar
            </Typography>
              <div className={classes.heroButtons}>
                <Grid container spacing={2} justify="center">
                  <Grid item>
                    {/* <Button variant="contained" color="secondary" href="./hosting/#contained-buttons">
                      Main call to action
                    </Button> */}
  
                  </Grid>
                </Grid>
              </div>
          </Container>
        </div>
    </React.Fragment>
  );
}
