import React, { useState } from 'react';
import {ReactComponent as Isotipo}  from '../images/isotipo.svg';
import './common.css';

// Materials UI
// import Toolbar from '@material-ui/core/Toolbar';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
  faChartLine,
  faFileCode,
  faEnvelopeOpen
} from "@fortawesome/free-solid-svg-icons";
import { 
  Container, 
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
} from 'reactstrap';
import {
  Link,
} from "react-router-dom";

export default function Header() {

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const close = () => setIsOpen(false);

  return (
    <nav className="shadow ">
      <Navbar expand="lg">
        <Container>
          <Link className="navbar-brand align-items-center col-3" to="/">
              <Isotipo className="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-4 logo mb-3" alt="Isotipo" />
              <span className="text-white h2 typed" alt="Logotipo">Social Media </span>
          </Link>
          {/* Menu */}
          <NavbarToggler className="navbar-dark " onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <Link to="/" className='nav-link' onClick={close}><FontAwesomeIcon icon={faFileCode} /> Home</Link>
              </NavItem>
              <NavItem>
                <Link to="/hosting" className='nav-link' onClick={close}><FontAwesomeIcon icon={faChartLine} /> Hosting</Link>
              </NavItem>
              {/* <NavItem>
                <Link to="/stripe" className='nav-link' onClick={close}><FontAwesomeIcon icon={faHandsHelping} /> Stripe</Link>
              </NavItem> */}
              <NavItem>
                <Link to="/contact" className='nav-link' onClick={close}><FontAwesomeIcon icon={faEnvelopeOpen} /> Contact</Link>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </nav>
  );
}