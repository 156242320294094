import React from 'react';
import './style.css';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// get our fontawesome imports
import { 
  faChartPie,
  faShieldAlt,
  faRocket,
  faHeadset,
} from "@fortawesome/free-solid-svg-icons";

// Bootstrap
import { 
  Container, Row, Col,
} from 'reactstrap';


export default function Services() {

  return (
    <React.Fragment>
      <section id="services" className="py-5 p-sm-3 bg-dark text-light">
        {/* Services */}
        <Container className="py-5">
        <h2 className="display-1 font-weight-bold pl-sm-3">What We Do</h2>
          <Row className="mb-4 mb-lg-7">
              <Col className="col-12 col-sm-6 col-lg-3 mb-5 mb-lg-0 text-center escale rounded icon-card pt-3">
                  {/* Icon */}
                  <div className="icon icon-shape icon-shape-primary rounded-circle">
                    <FontAwesomeIcon icon={faChartPie} /> 
                  </div>
                  {/* Heading */}
                  <h3 className="mt-4 mb-3 h2">Media</h3>
                  <p className="px-0 px-sm-4 px-lg-0">Discover new and measurable opportunities to target, reach, and monetize your audiences across all channels.</p>
                  {/* <a href="./about.html" className="btn btn-link text-primary">Learn more <span className="icon icon-xs ml-2"><FontAwesomeIcon icon={faArrowRight} /> </span></a> */}
              </Col>
              <Col className="col-12 col-sm-6 col-lg-3 mb-5 mb-lg-0 text-center escale rounded icon-card pt-3">
                  {/* Icon */}
                  <div className="icon icon-shape icon-shape-primary rounded-circle ">
                    <FontAwesomeIcon icon={faShieldAlt} /> 
                  </div>
                  {/* Heading */}
                  <h3 className="mt-4 mb-3 h2">Data</h3>
                  <p className="px-0 px-sm-4 px-lg-0">Unlock real, accurate, and verified data using our consumer-managed data platform.</p>
                  {/* <a href="./about.html" className="btn btn-link text-primary">Learn more <span className="icon icon-xs ml-2"><FontAwesomeIcon icon={faArrowRight} /> </span></a> */}
              </Col>
              <Col className="col-12 col-sm-6 col-lg-3 mb-5 mb-lg-0 text-center escale rounded icon-card pt-3">
                  {/* Icon */}
                  <div className="icon icon-shape icon-shape-primary rounded-circle">
                    <FontAwesomeIcon icon={faRocket} /> 
                  </div>
                  {/* Heading */}
                  <h3 className="mt-4 mb-3 h2">Marketing</h3>
                  <p className="px-0 px-sm-4 px-lg-0">Generate brand awareness and loyalty from your online presence with our managed social services team.</p>
                  {/* <a href="./about.html" className="btn btn-link text-primary">Learn more <span className="icon icon-xs ml-2"><FontAwesomeIcon icon={faArrowRight} /> </span></a> */}
              </Col>
              <Col className="col-12 col-sm-6 col-lg-3 mb-5 mb-lg-0 text-center escale rounded icon-card pt-3">
                  {/* Icon */}
                  <div className="icon icon-shape icon-shape-primary rounded-circle">
                    <FontAwesomeIcon icon={faHeadset} /> 
                  </div>
                  {/* Heading */}
                  <h3 className="mt-4 mb-3 h2">Technology</h3>
                  <p className="px-0 px-sm-4 px-lg-0">Amplify digital performance with our unique management platforms for media buying, social media, and investor relations.</p>
                  {/* <a href="./about.html" className="btn btn-link text-primary">Learn more <span className="icon icon-xs ml-2"><FontAwesomeIcon icon={faArrowRight} /> </span></a> */}
              </Col>
          </Row>        
        </Container>
      </section>
    </React.Fragment>
  );
}

// import Card from '@material-ui/core/Card';
// import CardActions from '@material-ui/core/CardActions';
// import CardContent from '@material-ui/core/CardContent';
// import CardMedia from '@material-ui/core/CardMedia';
// <Container className={classes.cardGrid} maxWidth="md">
//   {/* End hero unit */}
//   <Grid container spacing={4}>
//     {cards.map((card) => (
//       <Grid item key={card} xs={12} sm={6} md={4}>
//         <Card className={classes.card}>
//           <CardMedia
//             className={classes.cardMedia}
//             image="https://source.unsplash.com/random"
//             title="Image title"
//           />
//           <CardContent className={classes.cardContent}>
//             <Typography gutterBottom variant="h5" component="h2">
//               Heading
//             </Typography>
//             <Typography>
//               This is a media card. You can use this section to describe the content.
//             </Typography>
//           </CardContent>
//           <CardActions>
//             <Button size="small" color="primary">
//               View
//             </Button>
//             <Button size="small" color="primary">
//               Edit
//             </Button>
//           </CardActions>
//         </Card>
//       </Grid>
//     ))}
//   </Grid>
// </Container>