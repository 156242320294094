import React from 'react';
import Header from  './header';
import Form from './form'
export default function Contact() {

  return (
    <React.Fragment>
    {/* Header */}
    <Header />
    {/* Features */}
    <Form />
    </React.Fragment>
  );
}