import React from 'react';



import { 
  Container,
  Col,
  Row,
} from 'reactstrap';


export default function Contact() {

  return (
    <React.Fragment>
      <section id="contact" className="py-5">
        {/* Services */}
        <Container  className="py-5">
          <Row className="justify-content-center justify-content-md-center">
          <form className="js-validate mx-5" >
            <Row>
              <Col className="col-12 mb-4">
                <div className="js-form-message">
                  <input type="text" className="form-control" name="name" placeholder="Nombre" data-msg="Escriba su nombre."/>
                </div>
              </Col>
              <Col className="col-12 mb-4">
                <div className="js-form-message">
                  <input type="phone" className="form-control" name="phone" placeholder="Telefono" required="" data-msg="Ingrese un número de teléfono válido."/>
                </div>
              </Col>
              <Col className="col-12 mb-4">
                <div className="js-form-message">
                  <input type="email" className="form-control" name="email" placeholder="E-Mail" required="" data-msg="Introduce una dirección de correo electrónico válida."/>
                </div>
              </Col>
            </Row>
            <div className="js-form-message mb-6">
              <div className="input-group">
                <textarea className="form-control" rows="4" name="text" placeholder="como podemos ayudarte?" required="" data-msg="Ingrese su motivo."></textarea>
              </div>
            </div>
            <div className="text-center mx-5 my-4">
              <button id="button" type="submit" className="btn btn-dark rounded btn-wide transition-3d-hover">Enviar</button>
              <p className="small">Lo contactaremos en 1-2 días hábiles.</p>
            </div>
          </form>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
}



