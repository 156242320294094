import { 
    createMuiTheme,
    makeStyles, 
} from '@material-ui/core/styles';
  
import HeaderBack from '../images/header-bg-1.jpg';

export const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#757ce8',
      main: '#F6121C',
      dark: '#002884',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#1F1598',
      dark: '#ba000d',
      contrastText: '#000',
    },
  },
});

export const useStyles = makeStyles((theme) => ({
    '@global': {
      ul: {
        margin: 0,
        padding: 0,
        listStyle: 'none',
      },
      
    },
    
    colorTitle: {
      color: theme.palette.secondary.main,
    },
    HeaderBack: {
      backgroundImage: `url(${HeaderBack})`,
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(8, 0, 8),
      position: 'relative',
    },
    heroButtons: {
      marginTop: theme.spacing(4),
    },
    backgroundOverlay: {
      height: '100%',
      width: '100%',
      top: '0',
      left: '0',
      position: 'absolute',
      backgroundColor: theme.palette.common.black,
      opacity: '0.5',
    },
    link: {
      margin: theme.spacing(1, 1.5),
    },
    heroContent: {
      padding: theme.spacing(8, 0, 4), 
      position: 'relative',
    },
    textWhite: {
      color: theme.palette.primary.contrastText,
    },
    textLight: {
      color: '#f8f9fa',
    },
    icon: {
      color: theme.palette.secondary.main,
    },
    cardGrid: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
    },
    cards: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: 'transparent !important',
      boxShadow: 'none !important',
    },
    cardMedia: {
      height: '50px', 
      width: '50px',
      marginLeft: '50%',
      transform: 'translateX(-50%)',
    },
    cardContent: {
      flexGrow: 1,
      alignContent: 'center',
    },
    cardHeaderTitle: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
    },
    cardHeader: {
      backgroundColor:
        theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.secondary.main,
    },
    cardPricing: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'baseline',
      marginBottom: theme.spacing(2),
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    }
  }));