import React from 'react';
import Form from './form'
import {useStyles} from '../common/Palette'
import StarIcon from '@material-ui/icons/StarBorder';

import {
  CssBaseline,
  Container,
  Grid,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Typography,
  Dialog,
} from '@material-ui/core';

import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe('pk_live_QvQlraHc9ZtadxFzKYyB5mKP00Lu5sj4bb');

export default function Pricing() {

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const priceClickOne = () => {
      setOpen(true);
    };

    const priceClickTwo = async (event) => {
    // When the customer clicks on the button, redirect them to Checkout.
    const stripe = await stripePromise;
    const { error } = await stripe.redirectToCheckout({
      lineItems: [
        {
          price: 'price_1I76MVFicUF57Q48cgRQTsR1', 
          quantity: 1,
        }
      ],
      mode: 'subscription',
      successUrl: 'https://sjsocialmedia.com/checkout/success',
      cancelUrl: 'https://sjsocialmedia.com/checkout/cancel',
      billingAddressCollection: 'required',
  });
  }

  const priceClickThree = async (event) => {
    const stripe = await stripePromise;
    const { error } = await stripe.redirectToCheckout({
      lineItems: [
        {
          price: 'price_1I74KOFicUF57Q481vELHvJ4', 
          quantity: 1,
        }
      ],
      mode: 'subscription',
      successUrl: 'https://sjsocialmedia.com/checkout/success',
      cancelUrl: 'https://sjsocialmedia.com/checkout/cancel',
      billingAddressCollection: 'required',
  });
  }

  const tiers = [
    {
      title: 'WordPress Startup Program',
      price: '0',
      description: [`Hosting WordPress Pro`, `Elementor Pro`, `Backup Mensual`, `Uptime Monitor`, `Automated Security Check`, `Automated Performance Check`, `Link Monitor`],
      buttonText: 'Contactanos',
      buttonVariant: 'outlined',
      buttonId: priceClickOne,
    },
    {
      title: 'WordPress Administrado',
      // subheader: 'Most popular',
      price: '159.900',
      description: [`Hosting WordPress Pro`, `Elementor Pro`, `Backup Mensual`, `Uptime Monitor`, `Automated Security Check`, `Automated Performance Check`, `Link Monitor`],
      buttonText: 'Obtener',
      buttonVariant: 'contained',
      buttonId: priceClickTwo,
    },
    {
      title: 'WooCommerce Administrado',
      price: '399.900',
      description: [`Hosting Woocommerce Pro`, `Elementor Pro`, `Backup Diario`, `Uptime Monitor`, `Automated Security Check`, `Automated Performance Check`, `Link Monitor`],
      buttonText: 'Obtener',
      buttonVariant: 'outlined',
      buttonId: priceClickThree,
    },
  ];

  const classes = useStyles();
  
  return (
    <React.Fragment>
      <CssBaseline />
      <div className={classes.heroContent}>
        {/* Hero unit */}
        <Container maxWidth="sm" component="main" className={classes.heroContent} >
          <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
            Pricing
          </Typography>
          <Typography variant="h3" align="center" color="textPrimary" component="p">
            Experiencia Premium en Todos los Planes
          </Typography>
        </Container>
        {/* End hero unit */}
        <Container maxWidth="md" component="main">
          <Grid container spacing={5} alignItems="flex-end">
            {tiers.map((tier) => (
              // Enterprise card is full width at sm breakpoint
              <Grid item key={tier.title} xs={12} sm={tier.title === 'Enterprise' ? 12 : 6} md={4}>
                <Card>
                  <CardHeader
                    title={tier.title}
                    subheader={tier.subheader}
                    titleTypographyProps={{ align: 'center' }}
                    subheaderTypographyProps={{ align: 'center' }}
                    action={tier.title === 'WordPress Administrado' ? <StarIcon /> : null}
                    className={tier.title === 'WordPress Administrado' ? classes.cardHeaderTitle : classes.cardHeader}
                  />
                  <CardContent>
                    <div className={classes.cardPricing}>
                      <Typography component="h2" variant="h3" color="textPrimary">
                        ${tier.price}
                      </Typography>
                      <Typography variant="h6" color="textSecondary">
                        Anual
                      </Typography>
                    </div>
                    <ul>
                      {tier.description.map((line) => (
                        <Typography component="li" variant="subtitle1" align="center" key={line}>
                          {line}
                        </Typography>
                      ))}
                    </ul>
                  </CardContent>
                  <CardActions>
                    <Button fullWidth variant={tier.buttonVariant} color="secondary" onClick={tier.buttonId}>
                      {tier.buttonText}
                    </Button>
                    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                      <Form />
                    </Dialog>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
        <div className={classes.heroContent}></div>
      </div>
    </React.Fragment>
  );
}