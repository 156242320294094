import React from 'react';
import {ReactComponent as Logo}  from '../images/logo.svg';
import './common.css';
import CssBaseline from '@material-ui/core/CssBaseline';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// get our fontawesome imports
import { 
    faYoutube,
    faFacebook,
    faInstagram,
    faTwitter
} from "@fortawesome/free-brands-svg-icons";
import { 
    Container,
    Row,
    Col,
    NavLink
} from 'reactstrap';

export default function App() {
    
  
    return (
      <React.Fragment>

        <CssBaseline />
            <footer>
                <section className="">
                    <Container>
                        <Row className="pt-5 mx-2 mx-sm-0 text-light">
                            <Col className="col-12 col-sm-6 col-lg-3 my-1 my-sm-0 text-light">
                            <NavLink id="button" className="rounded" href="https://www.youtube.com/channel/UCX5w6KTXJAv219CrbmXb5_Q?sub_confirmation=1"><FontAwesomeIcon icon={faYoutube} /> Youtube</NavLink>
                            </Col>
                            <Col className="col-12 col-sm-6 col-lg-3 my-1 my-sm-0">
                            <NavLink id="button" className="rounded" href="https://www.facebook.com/SJ.SocialMedia/"><FontAwesomeIcon icon={faFacebook} /> Facebook</NavLink>          
                            </Col>
                            <Col className="col-12 col-sm-6 col-lg-3 my-1 my-sm-0">
                            <NavLink id="button" className="rounded" href="https://www.instagram.com/SJ.SocialMedia/"><FontAwesomeIcon icon={faInstagram} /> Instagram</NavLink>
                            </Col>
                            <Col className="col-12 col-sm-6 col-lg-3 my-1 my-sm-0">
                            <NavLink id="button" className="rounded" href="https://twitter.com/SJ_SocialMedia"><FontAwesomeIcon icon={faTwitter} /> Twitter</NavLink>       
                            </Col>
                        </Row> 
                    </Container>
                </section>
                <Container className="py-5">
                    <Row>
                        <Col className="col-7 col-lg-4 text-white my-auto">
                            <Logo className="navbar-brand-img logo" alt="logo" />    
                            <small className="d-block mb-3 text-muted">© 2011-2020</small>
                        </Col>
                        <Col className="col-5 col-sm-2">
                        
                        </Col>
                        <Col className="col-6 col-sm-6 col-lg-3 my-auto">
                            {/* Heading */} 
                            <h6 className="text-white">
                            Production office:
                            </h6>
                            {/* Text */} 
                            <p className="text-muted h5 mb-6 mb-md-0 text-center text-sm-right text-light">
                                CC Unicentro<br/>
                                Cra 100 #5-169<br/>
                                Cali, VA 76001
                            </p>
                        </Col>
                        <Col className="col-6 col-sm-6 col-lg-3 my-auto">
                            {/* Heading */} 
                            <h6 className="text-white">
                            US office:
                            </h6>
                            {/* Text */} 
                            <p className="text-muted h5 mb-6 mb-md-0 text-center text-sm-right text-light">
                                Security Building<br/>
                                117 NE 1st Ave<br/>
                                Miami, FL 33132
                            </p>
                        </Col>
                    </Row>
                </Container>
            </footer>

      </React.Fragment>
    );
  }