import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Header from './common/Header';
import Footer from './common/Footer';

import Hosting from './hosting';
import Home from './home';
import Contact from './contact'
import Success from './checkout/Success'
import Cancel from './checkout/Cancel'
// import  Stripe  from "./hosting/stripe";

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';


ReactDOM.render(
  <React.Fragment>
    {/* A <Switch> looks through its children <Route>s and
    renders the first one that matches the current URL. */}
      <Router>
        <Header/>
        <Switch>

          <Route path="/checkout/cancel">
            <Cancel />
          </Route>

          <Route path="/checkout/success">
            <Success />
          </Route>

          <Route path="/contact">
            <Contact />
          </Route>
          {/* <Route path="/stripe">
            <Stripe />
          </Route> */}
          
          <Route path="/hosting">
            <Hosting />
          </Route>

          <Route path="/">
            <Home />
          </Route>

        </Switch>
      </Router>
    <Footer />
  </React.Fragment>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();




